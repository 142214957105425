import React, {ChangeEvent} from "react";
import styles from "./index.module.css";
import {Button, DropdownItemProps, DropdownProps, Form, Grid, Loader, Select, TextArea} from "semantic-ui-react";
import agent, {PaginateOptions} from "../../agent";
import {Dept, DeptOptions, Doctor, SpecialGroup, SpecialGroupOptions} from "../dashboard";
import {Link, Redirect} from "react-router-dom";
import {DeptPaginationResponse} from "../workTableDepts/DeptList";
import {Utils} from "../../helpers/Utils";
import {AppMode, DoctorTitleCase, GeneralTitleCase, getDoctorTitle, getGeneralTitle} from "../../utils/common";

interface Props {
    match?: { params: { id: string; }, path: string; };
}

interface State {
    editable: boolean,
    _id: string,
    username: string,
    name: string,
    middlename: string,
    surname: string,
    tel: string,
    email: string,
    deptId?: string,
    deptIds: string[],
    deptName?: string,
    loading: boolean,
    saved: boolean,
    depts: Dept[],
    editMode: boolean;
    login?: string;
    password?: string;
    password2?: string;
    passwordChangeMode: boolean;
    passwordError?: string;
    password2Error?: string;
    roleEnableScan: boolean,
    roleEnableFileUpload?: boolean
}

class DoctorEditor extends React.PureComponent<Props, State> {
    state: State = {
        editable: false,
        _id: '',
        username: '',
        name: '',
        middlename: '',
        surname: '',
        tel: '',
        email: '',
        depts: [],
        deptIds: [],
        loading: false,
        editMode: false,
        saved: false,
        passwordChangeMode: false,
        roleEnableScan: false,
        roleEnableFileUpload: false
    };

    async componentDidMount() {
        await this.getDeptList();
        if (this.props.match && this.props.match.path.includes('edit')) {
            this.setState({editMode: true, loading: true, passwordChangeMode: false});
            await this.onMount();
        } else {
            this.setState({editable: true, loading: false, passwordChangeMode: true})
        }
    };

    onMount = async (): Promise<void> => {
        const doctorId: string = this.props.match ? this.props.match.params.id : '';
        const doc: Doctor = await agent.DoctorProvider.open(doctorId);

        if (doc) {
            this.setState({
                _id: doc._id,
                username: doc.username,
                name: doc.name,
                middlename: doc.middlename,
                surname: doc.surname,
                tel: doc.tel,
                email: doc.email,
                deptId: doc.deptId,
                deptIds: doc.deptIds ? doc.deptIds : [],
                deptName: doc.deptName,
                loading: false,
                login: doc.login,
                passwordChangeMode: !doc.hasPassword,
                roleEnableScan: !!doc.roleEnableScan,
                roleEnableFileUpload: !!doc.roleEnableFileUpload
            });
        }
    };

    onCreate = async (): Promise<void> => {
        if (!this.checkPasswords()) return;
        this.setState({loading: true});

        await agent.DoctorProvider.create({
            _id: '',
            username: this.state.username,
            name: this.state.name,
            middlename: this.state.middlename,
            surname: this.state.surname,
            tel: this.state.tel,
            email: this.state.email,
            deptId: this.state.deptId,
            deptIds: this.state.deptIds,
            deptName: this.state.deptName,
            login: this.state.login,
            password: this.state.password,
            roleEnableScan: this.state.roleEnableScan,
            roleEnableFileUpload: this.state.roleEnableFileUpload
        });

        this.setState({loading: false, saved: true});
    };

    onEdit = async () => {
        if (!this.checkPasswords()) return;
        this.setState({loading: true});

        await agent.DoctorProvider.edit({
            _id: this.state._id,
            username: this.state.username,
            name: this.state.name,
            middlename: this.state.middlename,
            surname: this.state.surname,
            tel: this.state.tel,
            email: this.state.email,
            deptId: this.state.deptId,
            deptIds: this.state.deptIds,
            deptName: this.state.deptName,
            login: this.state.login,
            password: this.state.password,
            roleEnableScan: this.state.roleEnableScan,
            roleEnableFileUpload: this.state.roleEnableFileUpload
        }).then((doc: Doctor) => {
            this.setState({
                _id: doc._id,
                username: doc.username,
                name: doc.name,
                middlename: doc.middlename,
                surname: doc.surname,
                tel: doc.tel,
                email: doc.email,
                deptId: this.state.deptId,
                deptIds: this.state.deptIds,
                deptName: this.state.deptName,
                editable: false,
                login: doc.login,
                roleEnableScan: !!doc.roleEnableScan,
                roleEnableFileUpload: !!doc.roleEnableFileUpload
            });
        });

        this.setState({loading: false, saved: true});
    };

    isSaveDisabled = (): boolean => {
        return !this.state.surname
    };

    checkPasswords = (): boolean => {
        if (this.state.editable && (window.config?.useDoctorAuthenticationByPassword === true) && this.state.passwordChangeMode) {
            if (!!this.state.login) {
                if (!this.state.password) {
                    this.setState({passwordError: "Задайте пароль пользователя"});
                    return false;
                } else if ((this.state.password?.length ?? 0) < 4) {
                    this.setState({passwordError: "Минимальная длина пароля - 4 символа!"});
                    return false;
                } else if (this.state.password! !== this.state.password2) {
                    this.setState({password2Error: "Введенные пароли не совпадают!"});
                    return false;
                }
            }
        }
        return true;
    };

    getDeptList = async (): Promise<void> => {
        this.setState({loading: true});

        const paginateOptions: PaginateOptions = {
            limit: 10000,
            page: 1,
            search: "",
            sort: "name"
        };

        const response: DeptPaginationResponse = await agent.DeptProvider.getList(paginateOptions);

        if (response) {
            this.setState({
                depts: response.docs
            });
        } else {
            this.setState({loading: false});
        }
    };

    getDeptOptions = (): DeptOptions[] => {
        return this.state.depts.map((el: Dept) => ({
            key: el._id,
            text: `${el.name}`,
            value: el._id,
        }))
    };

    getSelectedDepts = (): string => {
        const selecteddepts = this.state.depts.filter(dept => this.state.deptIds?.includes(dept._id.toString()));
        return selecteddepts ? selecteddepts.map(dept => dept.name).join("; ") : '';
    };

    render() {
        const headerLabel: string = this.state.editMode ? `Данные ${getDoctorTitle(DoctorTitleCase.a)}` : `Создание ${getDoctorTitle(DoctorTitleCase.a)}`;
        const showLogin: boolean = window.config?.useDoctorAuthenticationByPassword === true;
        const showPassword: boolean = showLogin && this.state.editable && this.state.passwordChangeMode;



        return (
            this.state.saved ?
                <Redirect to={'/dashboard/doctors'}/>
                :
                <div className={styles.doctorInfoContent}>
                    <div className={styles.doctorInfoHeader}>
                        {headerLabel}
                    </div>
                    {
                        this.state.loading ?
                            <Loader active/>
                            :
                            <Form>
                                <Grid>
                                    <Grid.Column width={4}>
                                        <Form.Input label={'Фамилия'}
                                                    value={this.state.surname}
                                                    readOnly={!this.state.editable}
                                                    onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({surname: e.target.value})}
                                        />
                                        <Form.Input label={'Имя'}
                                                    value={this.state.name}
                                                    readOnly={!this.state.editable}
                                                    onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({name: e.target.value})}
                                        />
                                        <Form.Input label={'Отчество'}
                                                    value={this.state.middlename}
                                                    readOnly={!this.state.editable}
                                                    onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({middlename: e.target.value})}
                                        />
                                        <Form.Input label={'Электронная почта'}
                                                    value={this.state.email}
                                                    readOnly={!this.state.editable}
                                                    onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({email: e.target.value})}
                                        />
                                        <Form.Input label={'Номер телефона'}
                                                    value={this.state.tel}
                                                    readOnly={!this.state.editable}
                                                    onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({tel: e.target.value})}
                                        />
                                        <Form.Input label={'Telegram username'}
                                                    value={this.state.username}
                                                    readOnly={!this.state.editable}
                                                    onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({username: e.target.value})}
                                        />

                                        <div>
                                            <label style={{fontWeight: 'bold', fontSize: '12px', display: 'block'}}>{getGeneralTitle(GeneralTitleCase.departmanttab)}</label>
                                            {this.state.editable ?
                                                    <Select
                                                        multiple
                                                        search={true}
                                                        value={this.state.deptIds}
                                                        options={this.getDeptOptions().map((option) => ({
                                                            value: option.value,
                                                            text: option.text,
                                                        }))}
                                                        onChange={(e, { value }) => {
                                                            if (value !== undefined) {
                                                                this.setState({ deptIds: value as string[] });
                                                            }
                                                        }}
                                                        disabled={!this.state.editable}
                                                    />
                                                :
                                                <TextArea
                                                            value={this.getSelectedDepts()}
                                                            rows={2}
                                                            readOnly />
                                        }
                                    </div>
                                    </Grid.Column>

                                    <Grid.Column width={6}>
                                        <div className={styles.doctorEditorButtons}>

                                            {
                                                this.state.editable ?
                                                    <Button
                                                        className={styles.button}
                                                        color={'green'}
                                                        type={'submit'}
                                                        content={'Сохранить'}
                                                        disabled={this.isSaveDisabled()}
                                                        onClick={this.state.editMode ? this.onEdit : this.onCreate}
                                                    />
                                                    :
                                                    <Button
                                                        className={styles.button}
                                                        color={'teal'}
                                                        type={'submit'}
                                                        content={'Изменить'}
                                                        onClick={() => this.setState({editable: true})}
                                                    />
                                            }

                                            <Link to={`/dashboard/doctors`}>
                                                <Button
                                                    className={styles.button}
                                                    color={'grey'}
                                                    type={'submit'}
                                                    content={'Назад'}
                                                />
                                            </Link>

                                        </div>
                                        <div className={styles.doctorEditorPassword}>
                                            {showLogin && <Form.Input label={'Логин'}
                                                                      value={this.state.login}
                                                                      readOnly={!this.state.editable}
                                                                      onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({login: e.target.value})}
                                            />}

                                            {showPassword && <div>
                                                <Form.Input label={'Введите пароль'}
                                                            type={"password"}
                                                            value={this.state.password}
                                                            readOnly={!this.state.editable}
                                                            error={this.state.passwordError}
                                                            onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({password: e.target.value})}
                                                />
                                                <Form.Input label={'Повторите пароль'}
                                                            type={"password"}
                                                            value={this.state.password2}
                                                            readOnly={!this.state.editable}
                                                            error={this.state.password2Error}
                                                            onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({password2: e.target.value})}
                                                /></div>}

                                            {this.state.editable && showLogin && !this.state.passwordChangeMode &&
                                            <Form.Button content={"Сменить пароль"} color={"orange"} onClick={() => {
                                                this.setState({passwordChangeMode: true});
                                            }}/>}


                                            {window.config?.moniki == true &&
                                            <Form.Checkbox label={"Доступно распознавание анализов"}
                                                           checked={this.state.roleEnableScan}
                                                           disabled={!this.state.editable}
                                                           onChange={(event, data) =>
                                                               this.setState({
                                                                   roleEnableScan: data.checked === true
                                                               })}/>
                                            }

                                            {window.config?.moniki == true &&
                                            <Form.Checkbox label={"Доступна загрузка файлов"}
                                                           checked={this.state.roleEnableFileUpload}
                                                           disabled={!this.state.editable}
                                                           onChange={(event, data) =>
                                                               this.setState({
                                                                   roleEnableFileUpload: data.checked === true
                                                               })}/>}
                                        </div>

                                    </Grid.Column>
                                </Grid>
                            </Form>
                    }
                </div>
        )
    }
}

export default DoctorEditor;